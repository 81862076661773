@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --tw-color-primary-50: 47 160 146;
  --tw-color-primary-100: 204 251 241;
  --tw-color-primary-200: 153 246 228;
  --tw-color-primary-300: 47 144 128;
  --tw-color-primary-400: 111 191 172;
  --tw-color-primary-500: 47 144 128;
  --tw-color-primary-600: 47 144 128;
  --tw-color-primary-700: 103, 191, 172;
  --tw-color-primary-800: 111 191 172;
  --tw-color-primary-900: 111 191 172;
  --tw-color-primary-1000: 163, 255, 235;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  --color-primary-100: rgb(var(--tw-color-primary-100));
  --color-primary-200: rgb(var(--tw-color-primary-200));
  --color-primary-300: rgb(var(--tw-color-primary-300));
  --color-primary-400: rgb(var(--tw-color-primary-400));
  --color-primary-500: rgb(var(--tw-color-primary-500));
  --color-primary-600: rgb(var(--tw-color-primary-600));
  --color-primary-700: rgb(var(--tw-color-primary-700));
  --color-primary-800: rgb(var(--tw-color-primary-800));
  --color-primary-900: rgb(var(--tw-color-primary-900));
  --color-primary-1000: rgb(var(--tw-color-primary-1000));

}


@layer base {

  /* inter var - latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100 900;
    font-display: optional;
    src: url('/fonts/inter-var-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: bold;
    src: url('/fonts/Gilroy-Black.otf') format('otf');
  }

  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: bold;
    src: url('/fonts/Gilroy-BlackItalic.otf') format('otf');
  }


  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: bold;
    src: url('/fonts/Gilroy-Bold.otf') format('otf');
  }

  @font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: bold;
    src: url('/fonts/Gilroy-BoldItalic.otf') format('otf');
  }

  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: bold;
    src: url('/fonts/Gilroy-ExtraBold.otf') format('otf');
  }

  @font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: bold;
    src: url('/fonts/Gilroy-ExtraBoldItalic.otf') format('otf');
  }

  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    src: url('/fonts/Gilroy-Heavy.otf') format('otf');
  }

  @font-face {
    font-family: 'Gilroy';
    font-style: italic;
    src: url('/fonts/Gilroy-HeavyItalic.otf') format('otf');
  }

  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: lighter;
    src: url('/fonts/Gilroy-Light.otf') format('otf');
  } 
  @font-face {
    font-family: 'Gilroy';
    font-style: italic;
    src: url('/fonts/Gilroy-LightItalic.otf') format('otf');
  }

  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    src: url('/fonts/Gilroy-Medium.otf') format('otf');
  } 

  @font-face {
    font-family: 'Gilroy';
    font-style: italic;
    src: url('/fonts/Gilroy-MediumItalic.otf') format('otf');
  } 

  @font-face {
    font-family: 'gilroy-regular';
    font-style: normal;
    src: url('/fonts/Gilroy-Regular.otf') format('otf');
  } 

  @font-face {
    font-family: 'Gilroy';
    font-style: italic;
    src: url('/fonts/Gilroy-RegularItalic.otf') format('otf');
  } 

  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    src: url('/fonts/Gilroy-Semibold.otf') format('otf');
  } 

  @font-face {
    font-family: 'Gilroy';
    font-style: italic;
    src: url('/fonts/Gilroy-SemiboldItalic.otf') format('otf');
  } 

  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    src: url('/fonts/Gilroy-Thin.otf') format('otf');
  } 

  @font-face {
    font-family: 'ThinItalic';
    font-style: italic;
    src: url('/fonts/Gilroy-ThinItalic.otf') format('otf');
  } 
  
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    src: url('/fonts/Gilroy-UltraLight.otf') format('otf');
  } 
  
  @font-face {
    font-family: 'Gilroy';
    font-style: italic;
    src: url('/fonts/Gilroy-UltraLightItalic.otf') format('otf');
  } 
  
  .cursor-newtab {
    cursor: url('/images/new-tab.png') 10 10, pointer;
  }

  /* #region  /**=========== Typography =========== */
  .h0 {
    @apply font-primary text-3xl font-bold md:text-5xl;
  }

  h1,
  .h1 {
    @apply font-primary text-2xl font-bold md:text-4xl;
  }

  h2,
  .h2 {
    @apply font-primary text-xl font-bold md:text-3xl;
  }

  h3,
  .h3 {
    @apply font-primary text-lg font-bold md:text-2xl;
  }

  h4,
  .h4 {
    @apply font-primary text-base font-bold md:text-lg;
  }

  body,
  .p {
    @apply font-primary text-sm md:text-base;
  }

  /* #endregion  /**======== Typography =========== */

  .layout {
    /* 1100px */
    max-width: 68.75rem;
    @apply mx-auto w-11/12;
  }

  .bg-dark a.custom-link {
    @apply border-gray-200 hover:border-gray-200/0;
  }

  /* Class to adjust with sticky footer */
  .min-h-main {
    @apply min-h-[calc(100vh-56px)];
  }
}

@layer utilities {
  .animated-underline {
    background-image: linear-gradient(#33333300, #33333300),
      linear-gradient(to right,
        var(--color-primary-400),
        var(--color-primary-500));
    background-size: 100% 2px, 0 2px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
  }

  @media (prefers-reduced-motion: no-preference) {
    .animated-underline {
      transition: 0.3s ease;
      transition-property: background-size, color, background-color,
        border-color;
    }
  }

  .animated-underline:hover,
  .animated-underline:focus-visible {
    background-size: 0 2px, 100% 2px;
  }

  .login-input {
    width: 327px;
    border-radius: 10px;
    height: 50px;
    font-size: 13px;
    transition:all .3s;
    border-color: #797979;
  }
  .form-label {
    font-size: 14px;
    color: #707070;
    display: block;
    opacity: 1;
    transform:translateY(-2.5em);
    transform-origin:0 0;
    transition:all .3s;
    pointer-events: none;
    padding-left: 4%;
  }
  .login-form {
    width: 100%;
    max-width: 600px;
    height: 48px;
    margin: 0 auto;
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    transform:all .5s;
  }
  .login-input::placeholder {
    color: transparent;
  }
  .login-input:focus {
    box-shadow:none;
    outline:none;
    border-color: #67BFAC;
  }
  .login-input:focus + .form-label , .login-input:not(:placeholder-shown)  + .form-label
  {
    transform:translateY(-3.4em) scale(.7);
    border-color: #67BFAC;
  }
  .login-input:focus , .login-input:not(:placeholder-shown)
  {
    border-color: #67BFAC;
  }
  .inputField:focus , .inputField:not(:placeholder-shown)
  {
    border-color: #67BFAC;
  }
  .radio-input[type="radio"]:checked + span {
    display: block;
    background-color: rgba(163, 255, 235, 0.5);

  }
}