/* //!STARTERCONF Remove this file after copying your desired color, this is a large file you should remove it. */

.rose {
  --tw-color-primary-50: 255 241 242;
  --tw-color-primary-100: 255 228 230;
  --tw-color-primary-200: 254 205 211;
  --tw-color-primary-300: 253 164 175;
  --tw-color-primary-400: 251 113 133;
  --tw-color-primary-500: 244 63 94;
  --tw-color-primary-600: 225 29 72;
  --tw-color-primary-700: 190 18 60;
  --tw-color-primary-800: 159 18 57;
  --tw-color-primary-900: 136 19 55;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #fff1f2 */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #ffe4e6 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #fecdd3 */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #fda4af */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #fb7185 */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #f43f5e */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #e11d48 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #be123c */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #9f1239 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #881337 */
}

.pink {
  --tw-color-primary-50: 253 242 248;
  --tw-color-primary-100: 252 231 243;
  --tw-color-primary-200: 251 207 232;
  --tw-color-primary-300: 249 168 212;
  --tw-color-primary-400: 244 114 182;
  --tw-color-primary-500: 236 72 153;
  --tw-color-primary-600: 219 39 119;
  --tw-color-primary-700: 190 24 93;
  --tw-color-primary-800: 157 23 77;
  --tw-color-primary-900: 131 24 67;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #fdf2f8 */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #fce7f3 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #fbcfe8 */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #f9a8d4 */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #f472b6 */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #ec4899 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #db2777 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #be185d */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #9d174d */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #831843 */
}

.fuchsia {
  --tw-color-primary-50: 253 244 255;
  --tw-color-primary-100: 250 232 255;
  --tw-color-primary-200: 245 208 254;
  --tw-color-primary-300: 240 171 252;
  --tw-color-primary-400: 232 121 249;
  --tw-color-primary-500: 217 70 239;
  --tw-color-primary-600: 192 38 211;
  --tw-color-primary-700: 162 28 175;
  --tw-color-primary-800: 134 25 143;
  --tw-color-primary-900: 112 26 117;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #fdf4ff */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #fae8ff */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #f5d0fe */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #f0abfc */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #e879f9 */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #d946ef */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #c026d3 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #a21caf */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #86198f */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #701a75 */
}

.purple {
  --tw-color-primary-50: 250 245 255;
  --tw-color-primary-100: 243 232 255;
  --tw-color-primary-200: 233 213 255;
  --tw-color-primary-300: 216 180 254;
  --tw-color-primary-400: 192 132 252;
  --tw-color-primary-500: 168 85 247;
  --tw-color-primary-600: 147 51 234;
  --tw-color-primary-700: 126 34 206;
  --tw-color-primary-800: 107 33 168;
  --tw-color-primary-900: 88 28 135;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #faf5ff */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #f3e8ff */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #e9d5ff */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #d8b4fe */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #c084fc */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #a855f7 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #9333ea */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #7e22ce */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #6b21a8 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #581c87 */
}

.violet {
  --tw-color-primary-50: 245 243 255;
  --tw-color-primary-100: 237 233 254;
  --tw-color-primary-200: 221 214 254;
  --tw-color-primary-300: 196 181 253;
  --tw-color-primary-400: 167 139 250;
  --tw-color-primary-500: 139 92 246;
  --tw-color-primary-600: 124 58 237;
  --tw-color-primary-700: 109 40 217;
  --tw-color-primary-800: 91 33 182;
  --tw-color-primary-900: 76 29 149;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #f5f3ff */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #ede9fe */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #ddd6fe */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #c4b5fd */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #a78bfa */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #8b5cf6 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #7c3aed */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #6d28d9 */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #5b21b6 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #4c1d95 */
}

.indigo {
  --tw-color-primary-50: 238 242 255;
  --tw-color-primary-100: 224 231 255;
  --tw-color-primary-200: 199 210 254;
  --tw-color-primary-300: 165 180 252;
  --tw-color-primary-400: 129 140 248;
  --tw-color-primary-500: 99 102 241;
  --tw-color-primary-600: 79 70 229;
  --tw-color-primary-700: 67 56 202;
  --tw-color-primary-800: 55 48 163;
  --tw-color-primary-900: 49 46 129;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #eef2ff */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #e0e7ff */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #c7d2fe */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #a5b4fc */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #818cf8 */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #6366f1 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #4f46e5 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #4338ca */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #3730a3 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #312e81 */
}

.blue {
  --tw-color-primary-50: 239 246 255;
  --tw-color-primary-100: 219 234 254;
  --tw-color-primary-200: 191 219 254;
  --tw-color-primary-300: 147 197 253;
  --tw-color-primary-400: 96 165 250;
  --tw-color-primary-500: 59 130 246;
  --tw-color-primary-600: 37 99 235;
  --tw-color-primary-700: 29 78 216;
  --tw-color-primary-800: 30 64 175;
  --tw-color-primary-900: 30 58 138;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #eff6ff */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #dbeafe */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #bfdbfe */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #93c5fd */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #60a5fa */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #3b82f6 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #2563eb */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #1d4ed8 */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #1e40af */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #1e3a8a */
}

.sky {
  --tw-color-primary-50: 240 249 255;
  --tw-color-primary-100: 224 242 254;
  --tw-color-primary-200: 186 230 253;
  --tw-color-primary-300: 125 211 252;
  --tw-color-primary-400: 56 189 248;
  --tw-color-primary-500: 14 165 233;
  --tw-color-primary-600: 2 132 199;
  --tw-color-primary-700: 3 105 161;
  --tw-color-primary-800: 7 89 133;
  --tw-color-primary-900: 12 74 110;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #f0f9ff */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #e0f2fe */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #bae6fd */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #7dd3fc */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #38bdf8 */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #0ea5e9 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #0284c7 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #0369a1 */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #075985 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #0c4a6e */
}

.cyan {
  --tw-color-primary-50: 236 254 255;
  --tw-color-primary-100: 207 250 254;
  --tw-color-primary-200: 165 243 252;
  --tw-color-primary-300: 103 232 249;
  --tw-color-primary-400: 34 211 238;
  --tw-color-primary-500: 6 182 212;
  --tw-color-primary-600: 8 145 178;
  --tw-color-primary-700: 14 116 144;
  --tw-color-primary-800: 21 94 117;
  --tw-color-primary-900: 22 78 99;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #ecfeff */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #cffafe */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #a5f3fc */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #67e8f9 */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #22d3ee */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #06b6d4 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #0891b2 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #0e7490 */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #155e75 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #164e63 */
}

.teal {
  --tw-color-primary-50: 240 253 250;
  --tw-color-primary-100: 204 251 241;
  --tw-color-primary-200: 153 246 228;
  --tw-color-primary-300: 94 234 212;
  --tw-color-primary-400: 45 212 191;
  --tw-color-primary-500: 20 184 166;
  --tw-color-primary-600: 13 148 136;
  --tw-color-primary-700: 15 118 110;
  --tw-color-primary-800: 17 94 89;
  --tw-color-primary-900: 19 78 74;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #f0fdfa */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #ccfbf1 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #99f6e4 */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #5eead4 */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #2dd4bf */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #14b8a6 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #0d9488 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #0f766e */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #115e59 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #134e4a */
}

.emerald {
  --tw-color-primary-50: 236 253 245;
  --tw-color-primary-100: 209 250 229;
  --tw-color-primary-200: 167 243 208;
  --tw-color-primary-300: 110 231 183;
  --tw-color-primary-400: 52 211 153;
  --tw-color-primary-500: 16 185 129;
  --tw-color-primary-600: 5 150 105;
  --tw-color-primary-700: 4 120 87;
  --tw-color-primary-800: 6 95 70;
  --tw-color-primary-900: 6 78 59;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #ecfdf5 */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #d1fae5 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #a7f3d0 */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #6ee7b7 */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #34d399 */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #10b981 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #059669 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #047857 */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #065f46 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #064e3b */
}

.green {
  --tw-color-primary-50: 240 253 244;
  --tw-color-primary-100: 220 252 231;
  --tw-color-primary-200: 187 247 208;
  --tw-color-primary-300: 134 239 172;
  --tw-color-primary-400: 74 222 128;
  --tw-color-primary-500: 34 197 94;
  --tw-color-primary-600: 22 163 74;
  --tw-color-primary-700: 21 128 61;
  --tw-color-primary-800: 22 101 52;
  --tw-color-primary-900: 20 83 45;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #f0fdf4 */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #dcfce7 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #bbf7d0 */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #86efac */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #4ade80 */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #22c55e */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #16a34a */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #15803d */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #166534 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #14532d */
}

.lime {
  --tw-color-primary-50: 247 254 231;
  --tw-color-primary-100: 236 252 203;
  --tw-color-primary-200: 217 249 157;
  --tw-color-primary-300: 190 242 100;
  --tw-color-primary-400: 163 230 53;
  --tw-color-primary-500: 132 204 22;
  --tw-color-primary-600: 101 163 13;
  --tw-color-primary-700: 77 124 15;
  --tw-color-primary-800: 63 98 18;
  --tw-color-primary-900: 54 83 20;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #f7fee7 */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #ecfccb */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #d9f99d */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #bef264 */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #a3e635 */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #84cc16 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #65a30d */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #4d7c0f */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #3f6212 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #365314 */
}

.yellow {
  --tw-color-primary-50: 254 252 232;
  --tw-color-primary-100: 254 249 195;
  --tw-color-primary-200: 254 240 138;
  --tw-color-primary-300: 253 224 71;
  --tw-color-primary-400: 250 204 21;
  --tw-color-primary-500: 234 179 8;
  --tw-color-primary-600: 202 138 4;
  --tw-color-primary-700: 161 98 7;
  --tw-color-primary-800: 133 77 14;
  --tw-color-primary-900: 113 63 18;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #fefce8 */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #fef9c3 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #fef08a */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #fde047 */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #facc15 */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #eab308 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #ca8a04 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #a16207 */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #854d0e */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #713f12 */
}

.amber {
  --tw-color-primary-50: 255 251 235;
  --tw-color-primary-100: 254 243 199;
  --tw-color-primary-200: 253 230 138;
  --tw-color-primary-300: 252 211 77;
  --tw-color-primary-400: 251 191 36;
  --tw-color-primary-500: 245 158 11;
  --tw-color-primary-600: 217 119 6;
  --tw-color-primary-700: 180 83 9;
  --tw-color-primary-800: 146 64 14;
  --tw-color-primary-900: 120 53 15;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #fffbeb */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #fef3c7 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #fde68a */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #fcd34d */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #fbbf24 */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #f59e0b */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #d97706 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #b45309 */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #92400e */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #78350f */
}

.orange {
  --tw-color-primary-50: 255 247 237;
  --tw-color-primary-100: 255 237 213;
  --tw-color-primary-200: 254 215 170;
  --tw-color-primary-300: 253 186 116;
  --tw-color-primary-400: 251 146 60;
  --tw-color-primary-500: 249 115 22;
  --tw-color-primary-600: 234 88 12;
  --tw-color-primary-700: 194 65 12;
  --tw-color-primary-800: 154 52 18;
  --tw-color-primary-900: 124 45 18;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #fff7ed */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #ffedd5 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #fed7aa */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #fdba74 */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #fb923c */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #f97316 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #ea580c */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #c2410c */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #9a3412 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #7c2d12 */
}

.red {
  --tw-color-primary-50: 254 242 242;
  --tw-color-primary-100: 254 226 226;
  --tw-color-primary-200: 254 202 202;
  --tw-color-primary-300: 252 165 165;
  --tw-color-primary-400: 248 113 113;
  --tw-color-primary-500: 239 68 68;
  --tw-color-primary-600: 220 38 38;
  --tw-color-primary-700: 185 28 28;
  --tw-color-primary-800: 153 27 27;
  --tw-color-primary-900: 127 29 29;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #fef2f2 */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #fee2e2 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #fecaca */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #fca5a5 */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #f87171 */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #ef4444 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #dc2626 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #b91c1c */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #991b1b */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #7f1d1d */
}

.stone {
  --tw-color-primary-50: 250 250 249;
  --tw-color-primary-100: 245 245 244;
  --tw-color-primary-200: 231 229 228;
  --tw-color-primary-300: 214 211 209;
  --tw-color-primary-400: 168 162 158;
  --tw-color-primary-500: 120 113 108;
  --tw-color-primary-600: 87 83 78;
  --tw-color-primary-700: 68 64 60;
  --tw-color-primary-800: 41 37 36;
  --tw-color-primary-900: 28 25 23;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #fafaf9 */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #f5f5f4 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #e7e5e4 */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #d6d3d1 */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #a8a29e */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #78716c */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #57534e */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #44403c */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #292524 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #1c1917 */
}

.neutral {
  --tw-color-primary-50: 250 250 250;
  --tw-color-primary-100: 245 245 245;
  --tw-color-primary-200: 229 229 229;
  --tw-color-primary-300: 212 212 212;
  --tw-color-primary-400: 163 163 163;
  --tw-color-primary-500: 115 115 115;
  --tw-color-primary-600: 82 82 82;
  --tw-color-primary-700: 64 64 64;
  --tw-color-primary-800: 38 38 38;
  --tw-color-primary-900: 23 23 23;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #fafafa */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #f5f5f5 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #e5e5e5 */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #d4d4d4 */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #a3a3a3 */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #737373 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #525252 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #404040 */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #262626 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #171717 */
}

.zinc {
  --tw-color-primary-50: 250 250 250;
  --tw-color-primary-100: 244 244 245;
  --tw-color-primary-200: 228 228 231;
  --tw-color-primary-300: 212 212 216;
  --tw-color-primary-400: 161 161 170;
  --tw-color-primary-500: 113 113 122;
  --tw-color-primary-600: 82 82 91;
  --tw-color-primary-700: 63 63 70;
  --tw-color-primary-800: 39 39 42;
  --tw-color-primary-900: 24 24 27;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #fafafa */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #f4f4f5 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #e4e4e7 */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #d4d4d8 */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #a1a1aa */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #71717a */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #52525b */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #3f3f46 */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #27272a */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #18181b */
}

.gray {
  --tw-color-primary-50: 249 250 251;
  --tw-color-primary-100: 243 244 246;
  --tw-color-primary-200: 229 231 235;
  --tw-color-primary-300: 209 213 219;
  --tw-color-primary-400: 156 163 175;
  --tw-color-primary-500: 107 114 128;
  --tw-color-primary-600: 75 85 99;
  --tw-color-primary-700: 55 65 81;
  --tw-color-primary-800: 31 41 55;
  --tw-color-primary-900: 17 24 39;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #f9fafb */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #f3f4f6 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #e5e7eb */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #d1d5db */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #9ca3af */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #6b7280 */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #4b5563 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #374151 */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #1f2937 */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #111827 */
}

.slate {
  --tw-color-primary-50: 248 250 252;
  --tw-color-primary-100: 241 245 249;
  --tw-color-primary-200: 226 232 240;
  --tw-color-primary-300: 203 213 225;
  --tw-color-primary-400: 148 163 184;
  --tw-color-primary-500: 100 116 139;
  --tw-color-primary-600: 71 85 105;
  --tw-color-primary-700: 51 65 85;
  --tw-color-primary-800: 30 41 59;
  --tw-color-primary-900: 15 23 42;
  --color-primary-50: rgb(var(--tw-color-primary-50));
  /* #f8fafc */
  --color-primary-100: rgb(var(--tw-color-primary-100));
  /* #f1f5f9 */
  --color-primary-200: rgb(var(--tw-color-primary-200));
  /* #e2e8f0 */
  --color-primary-300: rgb(var(--tw-color-primary-300));
  /* #cbd5e1 */
  --color-primary-400: rgb(var(--tw-color-primary-400));
  /* #94a3b8 */
  --color-primary-500: rgb(var(--tw-color-primary-500));
  /* #64748b */
  --color-primary-600: rgb(var(--tw-color-primary-600));
  /* #475569 */
  --color-primary-700: rgb(var(--tw-color-primary-700));
  /* #334155 */
  --color-primary-800: rgb(var(--tw-color-primary-800));
  /* #1e293b */
  --color-primary-900: rgb(var(--tw-color-primary-900));
  /* #0f172a */
}